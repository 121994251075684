<template>
  <div>
    <p class="text-center">
      <strong> กลุ่มสินค้า </strong>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span style="float:right"><CIcon name="cil-x"></CIcon></span>
      </button>
    </p>
    <table style="width:100%;" aria-describedby="">
      <th scope="col"></th>
      <tr
        style=" border-bottom: 1px solid rgba(246,246,246);"
        data-dismiss="modal"
        v-for="category in categories"
        :key="category.objectId"
        :value="category.objectId"
        @click="goToByCategoryLists(category.objectId, category.name)"
      >
        <td style="width:15%;padding:8px">
          <div
            class="circle"
            :style="{
              'background-color': getCategoryColor(category.indexColor),
            }"
          >
            <h3>{{ catIcon(category.name) }}</h3>
          </div>
        </td>
        <td style="padding:10px">
          {{ category.name }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import poscrm from '@/services/poscrm'
import util from '@/util/util'

export default {
  props: ['shopLineObjectId'],
  data() {
    return {
      products: [],
      categories: [],
      branchName: '',
      totally: 0,
    }
  },
  computed: {
    uid() {
      return this.$route.query.uid
    },
  },
  created() {
    this.getCategory()
  },
  methods: {
    ...util,
    catIcon(catName) {
      return catName.charAt(0).toUpperCase()
    },
    getCategoryColor(indexColor) {
      return util.generateColor(indexColor)
    },
    goToByCategoryLists(objectId, name) {
      localStorage.setItem('categoryName', name)
      this.$router.push('/smart-menu/' + objectId + '/category?uid=' + this.uid)
    },
    getCategory() {
      const uid = this.uid

      let params = {
        shopObjectId: this.shopLineObjectId,
      }

      poscrm
        .get('/api/v1.0/' + uid + '/Shop/getcategory/view', { params: params })
        .then((res) => {
          this.categories = res.data.data
        })
    },
  },
  watch: {
    shopLineObjectId(newVal, oldVal) {
      this.shopLineObjectId = newVal
      this.getCategory()
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
    },
  },
}
</script>
