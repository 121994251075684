<template>
  <div class="p-2">
    <div v-if="products.length === 0" class="text-center mt-5">
      <img
        alt=""
        class="img-fluid"
        width="30%"
        src="https://cdn-icons-png.flaticon.com/512/776/776495.png"
        @error="noImgUrl"
      />
      <p class="mt-3 font-weight-bold">ไม่มีรายการสินค้า</p>
    </div>

    <div v-else class="row">
      <div v-if="display === 'list'" class="col p-1">
        <table class="mb-5" aria-describedby="" style="width:100%">
          <tr
            v-for="product in products"
            :key="product.objectId"
            style="border-bottom: 1px solid rgba(246,246,246);"
          >
            <td style="width:25%;" class="p-2">
              <div
                v-if="product.remoteImagePath"
                class="square-box shadow-menu"
                :style="{
                  'background-image': `url('${product.remoteImagePath}'),url('${noImg}')`,
                  'background-size': 'cover',
                  'background-position': 'center center',
                  width: '100%',
                }"
              ></div>
              <div
                v-else
                class="square-box shadow-menu"
                :style="{
                  'background-color': getImgColor(product.indexColor),
                  width: '100%',
                }"
              ></div>
            </td>
            <td class="pl-1">
              <div class="d-flex justify-content-between">
                <div class="mt-3 h6 text-grey autoShowHide">
                  {{ product.name }}
                </div>
                <small
                  v-if="product.favorite === true"
                  class="mt-2 text-success"
                  style="font-size:10px"
                >
                  <em class="fas fa-heart"></em>
                </small>
              </div>
              <div class="d-flex justify-content-between">
                <h6 class="text-black pb-3">
                  <strong>฿{{ product.ProductPRU[0].exceedUc }}</strong>
                </h6>
                <small>{{ product.unit.name }}/{{ product.SKURatio }}</small>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div
        v-else-if="display === 'grid'"
        class="col-6 pl-2 pr-2"
        v-for="product in products"
        :key="product.objectId"
      >
        <div
          v-if="product.remoteImagePath"
          class="square-box shadow-menu"
          :style="{
            'background-image': `url('${product.remoteImagePath}'),url('${noImg}')`,
            'background-size': 'cover',
            'background-position': 'center center',
            width: '100%',
          }"
        ></div>
        <div
          v-else
          class="square-box shadow-menu"
          :style="{
            'background-color': getImgColor(product.indexColor),
            width: '100%',
          }"
        ></div>
        <div class="d-flex justify-content-between">
          <div class="mt-2 h6 text-grey autoShowHide">
            {{ product.name }}
          </div>
          <small
            v-if="product.favorite === true"
            class="mt-2 text-success"
            style="font-size:10px"
          >
            <em class="fas fa-heart"></em>
          </small>
        </div>
        <div class="d-flex justify-content-between">
          <h6 class="text-black pb-3">
            <strong>฿{{ product.ProductPRU[0].exceedUc }}</strong>
          </h6>
          <small>{{ product.unit.name }}/{{ product.SKURatio }}</small>
        </div>
      </div>
    </div>
    <pagination
      v-show="showPaginate"
      :meta_data="meta_data"
      v-on:next="getProduct"
    ></pagination>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import poscrm from '@/services/poscrm'
import util from '@/util/util'
import Pagination from './Pagination'
import '@/util/menu.css'

export default {
  props: ['searchVal', 'display'],
  components: {
    Pagination,
  },
  data() {
    return {
      products: [],
      categoryName: '',
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
      },
      showPaginate: false,
    }
  },
  computed: {
    ...mapGetters(['shopLineObjectId']),
    uid() {
      return this.$route.query.uid
    },
    categoryObjectId() {
      return this.$route.params.categoryObjectId || ''
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
  },
  created() {
    this.getProduct()
  },
  methods: {
    ...util,
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE
    },
    getImgColor(indexColor) {
      return util.generateColor(indexColor)
    },
    getProduct(page = 1) {
      const uid = this.uid
      let categoryObjectId = this.categoryObjectId
      let params = {}

      if (categoryObjectId === '') {
        params = {
          shopObjectId: this.shopLineObjectId,
          page: page,
          limit: 50,
          searchVal: this.searchVal,
        }
      } else {
        params = {
          shopObjectId: this.shopLineObjectId,
          categoryObjectId: categoryObjectId,
          searchVal: this.searchVal,
          page: page,
          limit: 50,
        }
      }

      poscrm({
        method: 'get',
        url: '/api/v1.1/' + uid + '/Shop/getproduct/view',
        params: params,
      }).then((res) => {
        this.products = res.data.data
        this.meta_data.last_page = res.data.paginate.pageCount
        this.meta_data.current_page = res.data.paginate.currentPage

        let pageCount = res.data.paginate.pageCount || 0

        if (pageCount > 1) {
          this.showPaginate = true
        } else {
          this.showPaginate = false
        }
      })
    },
  },
  watch: {
    '$route.params.categoryObjectId'(val) {
      this.getProduct()
    },
    shopLineObjectId() {
      this.getProduct()
    },
    searchVal(newVal, oldVal) {
      this.searchVal = newVal
      this.getProduct()
    },
    display(newVal, oldVal) {
      this.display = newVal
    },
  },
}
</script>
