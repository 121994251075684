<template>
  <div>
    <div
      v-show="scrolled"
      class="navbar sticky-top bg-white"
      style="box-shadow: none;padding-top:20px"
    >
      <div class="search">
        <input
          type="text"
          class="form-control input-sm"
          placeholder="ค้นหาสินค้าโดยชื่อ..."
          v-model="searchVal"
        />
        <button type="submit" class="btn btnsearch btn-sm text-right">
          <em class="fas fa-search"></em> ค้นหา
        </button>
      </div>
    </div>
    <div
      v-show="isSearch"
      class="navbar fixed-top bg-white"
      style="box-shadow: none;padding-top:20px"
    >
      <div class="search">
        <input
          type="text"
          class="form-control input-sm"
          placeholder="ค้นหาสินค้าโดยชื่อ..."
          v-model="searchVal"
        />
        <button type="submit" class="btn btnsearch btn-sm text-right">
          <em class="fas fa-search"></em> ค้นหา
        </button>
      </div>
    </div>
    <CContainer>
      <nav-bar v-show="!isSearch"></nav-bar>
      <br v-show="isSearch" />
      <hr class="mt-0" />
      <table style="width:100%" aria-describedby="">
        <tr>
          <td>
            <h5 class="font-weight-bold">
              {{ categoryName }}
            </h5>
          </td>
          <td class="text-right">
            <small style="padding:10px;font-size:14px">
              <span style="padding:10px;" @click="handleDisplay('list')">
                <em class="fas fa-list"></em>
              </span>
              <span @click="handleDisplay('grid')">
                <em class="fas fa-th-large"></em>
              </span>
            </small>
          </td>
        </tr>
      </table>

      <br />

      <grid-menu
        v-bind:display="display"
        v-bind:searchVal="searchVal"
      ></grid-menu>

      <div class="modal fade modal-p-bottom" id="categoryModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body py-3">
              <category-lists
                v-bind:shopLineObjectId="shopLineObjectId"
              ></category-lists>
            </div>
          </div>
        </div>
      </div>
    </CContainer>
    <footer class="fixed-bottom mb-6">
      <a
        class="categorybtn text-success"
        data-toggle="modal"
        data-target="#categoryModal"
      >
        <em class="fas fa-layer-group"></em>
      </a>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CategoryLists from './CategoryLists'
import NavBar from './NavBar'
import GridMenu from './GridMenu'
import '@/util/menu.css'

export default {
  components: {
    CategoryLists,
    NavBar,
    GridMenu,
  },
  data() {
    return {
      products: [],
      categoryName: '',
      scrolled: false,
      searchVal: '',
      isSearch: false,
      display: 'grid',
    }
  },
  computed: {
    ...mapGetters(['shopLineObjectId']),
    uid() {
      return this.$route.query.uid
    },
    categoryObjectId() {
      return this.$route.params.categoryObjectId
    },
  },
  created() {
    this.categoryName = localStorage.getItem('categoryName')
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0
    },
    handleDisplay(opt) {
      this.display = opt
    },
  },
  watch: {
    '$route.params.categoryObjectId'(val) {
      this.searchVal = ''
    },
    searchVal(newVal, oldVal) {
      if (newVal !== '') {
        this.isSearch = true
      } else if (newVal === '') {
        this.isSearch = false
      }
    },
  },
}
</script>
