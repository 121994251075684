var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-2"},[(_vm.products.length === 0)?_c('div',{staticClass:"text-center mt-5"},[_c('img',{staticClass:"img-fluid",attrs:{"alt":"","width":"30%","src":"https://cdn-icons-png.flaticon.com/512/776/776495.png"},on:{"error":_vm.noImgUrl}}),_c('p',{staticClass:"mt-3 font-weight-bold"},[_vm._v("ไม่มีรายการสินค้า")])]):_c('div',{staticClass:"row"},[(_vm.display === 'list')?_c('div',{staticClass:"col p-1"},[_c('table',{staticClass:"mb-5",staticStyle:{"width":"100%"},attrs:{"aria-describedby":""}},_vm._l((_vm.products),function(product){return _c('tr',{key:product.objectId,staticStyle:{"border-bottom":"1px solid rgba(246,246,246)"}},[_c('td',{staticClass:"p-2",staticStyle:{"width":"25%"}},[(product.remoteImagePath)?_c('div',{staticClass:"square-box shadow-menu",style:({
                'background-image': `url('${product.remoteImagePath}'),url('${_vm.noImg}')`,
                'background-size': 'cover',
                'background-position': 'center center',
                width: '100%',
              })}):_c('div',{staticClass:"square-box shadow-menu",style:({
                'background-color': _vm.getImgColor(product.indexColor),
                width: '100%',
              })})]),_c('td',{staticClass:"pl-1"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"mt-3 h6 text-grey autoShowHide"},[_vm._v(" "+_vm._s(product.name)+" ")]),(product.favorite === true)?_c('small',{staticClass:"mt-2 text-success",staticStyle:{"font-size":"10px"}},[_c('em',{staticClass:"fas fa-heart"})]):_vm._e()]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('h6',{staticClass:"text-black pb-3"},[_c('strong',[_vm._v("฿"+_vm._s(product.ProductPRU[0].exceedUc))])]),_c('small',[_vm._v(_vm._s(product.unit.name)+"/"+_vm._s(product.SKURatio))])])])])}),0)]):(_vm.display === 'grid')?_vm._l((_vm.products),function(product){return _c('div',{key:product.objectId,staticClass:"col-6 pl-2 pr-2"},[(product.remoteImagePath)?_c('div',{staticClass:"square-box shadow-menu",style:({
          'background-image': `url('${product.remoteImagePath}'),url('${_vm.noImg}')`,
          'background-size': 'cover',
          'background-position': 'center center',
          width: '100%',
        })}):_c('div',{staticClass:"square-box shadow-menu",style:({
          'background-color': _vm.getImgColor(product.indexColor),
          width: '100%',
        })}),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"mt-2 h6 text-grey autoShowHide"},[_vm._v(" "+_vm._s(product.name)+" ")]),(product.favorite === true)?_c('small',{staticClass:"mt-2 text-success",staticStyle:{"font-size":"10px"}},[_c('em',{staticClass:"fas fa-heart"})]):_vm._e()]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('h6',{staticClass:"text-black pb-3"},[_c('strong',[_vm._v("฿"+_vm._s(product.ProductPRU[0].exceedUc))])]),_c('small',[_vm._v(_vm._s(product.unit.name)+"/"+_vm._s(product.SKURatio))])])])}):_vm._e()],2),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPaginate),expression:"showPaginate"}],attrs:{"meta_data":_vm.meta_data},on:{"next":_vm.getProduct}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }