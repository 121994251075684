<template>
  <div class="row mb-5">
    <div class="col text-left">
      <button
        class="btn btn-sm"
        v-if="meta_data.current_page != 1"
        @click="next(meta_data.current_page - 1)"
      >
        <CIcon name="cil-arrow-left"></CIcon> ย้อนกลับ
      </button>
      <button
        class="btn btn-sm"
        v-else-if="meta_data.current_page === 1"
        disabled
      ></button>
    </div>
    <div class="col text-right">
      <button
        class="btn btn-sm"
        v-if="meta_data.current_page === meta_data.last_page"
        disabled
      ></button>

      <button
        class="btn btn-sm"
        v-else
        @click="next(meta_data.current_page + 1)"
      >
        เพิ่มเติม <CIcon name="cil-arrow-right"></CIcon>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ['meta_data'],
  methods: {
    next(page) {
      this.$emit('next', page)
    },
  },
}
</script>
