<template>
  <div class="row">
    <div class="col text-right pb-2">
      <router-link :to="`/smart-menu?uid=${uid}`" class="text-black">
        <span style="padding:10px">
          <CIcon name="cil-x" size="lg"></CIcon>
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    uid() {
      return this.$route.query.uid
    },
  },
}
</script>
